import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import auth from './AuthUtils';
// import Cookies from 'js-cookie';
import { log } from '../config/log.js';
import ErrorBoundary from '../components/ErrorBoundaryComponent';

/**
 * This is meant to protect the routes and require that they have a valid jwt in order to access them. isAuthenticated
 * returns a boolean after the /home/test endpoint which takes a jwt and checks if it is valid or not
 * @param Component
 * @param rest
 * @returns {*}
 * @constructor
 */

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  let _cookie = localStorage.getItem('verified');

  const [authenticated, setAuthenticated] = React.useState(_cookie == 'true');

  if (
    window.location.pathname === '/dashboard' ||
    window.location.pathname === '/settings'
  ) {
    auth
      .isAuthenticated()
      .then(response => {
        console.log('Response', response);
        setAuthenticated(response);
      })
      .catch(error => {
        console.log('Error from protected route', error);
        setAuthenticated(error);
        if (error.message) {
          console.log('Being called from auth utils', error.message);
          auth.checksValidToken(error.message);
        }
      });
  }
  return (
    <ErrorBoundary>
      <Route
        {...rest}
        render={props => {
          if (authenticated) {
            // if(auth.isAuthenticated()) {

            return <Component {...props} />;
          } else {

            return (
              <Redirect
                to={{
                  pathname: '/login',
                  state: {
                    from: props.location
                  }
                }}
              />
            );
          }
        }}
      />
    </ErrorBoundary>
  );
};
