export const UPDATE_TIME_PERIOD = 'timePeriod:updateTimePeriod';

/**
 * Line graph needs to have currently selected time stored to share between the header and the dashboard. This is used
 * to update that value.
 * @param newTime
 * @returns {{payload: {timePeriod: *}, type: string}}
 */
export const updateTimePeriod = newTime => {
  return {
    type: UPDATE_TIME_PERIOD,
    payload: {
      timePeriod: newTime
    }
  };
};
