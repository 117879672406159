import { UPDATE_CALLS } from '../actions/updateCalls';

/**
 * Reducer that defines initial state and payload for each scope.
 * @param state
 * @param type
 * @param payload
 * @returns {callReducer|null|*}
 */
export default function callReducer(state = null, { type, payload }) {
  switch (type) {
    case UPDATE_CALLS:
      return payload.calls;
    default:
      return state;
  }
  return state;
}
