import axios from 'axios';
import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';
import { log } from '../config/log.js';
import { Redirect } from 'react-router-dom';
import React from 'react';
import moment from 'moment';
import 'moment-timezone';
import StorageUtils from '../utils/ApplicationStorageUtils';

let test;

let PASSWORD_REGEX = /^(?=.*\d)(?=.*[\W_])(?=.*[a-z])(?=.*[A-Z]).{6,100}$/;

class Auth {
  constructor(props) {
    this.authenticated = null;
  }

  /**
   * Takes email, password, includes a looping for error handling, tries 3 calls to back end before doing failCallback.
   * Callback is for success handling.
   * @param email
   * @param password
   * @param retries
   * @param callback
   * @param failCallback
   */
  login = (email, password, retries = 0, callback, failCallback) => {
    // localStorage.removeItem('oit_jwt');
    axios
      .post(
        process.env.REACT_APP_HOST +
          process.env.REACT_APP_PORT +
          '/users/login',
        {
          username: email,
          password: password
        }
      )
      .then(response => {
        // log('You just logged in', response);
        //get and store user avatar
        let token = response.data.data.token;
        let decoded = this.decodeToken(token);

        // log('This is decoded jwt', decoded);
        if (typeof decoded !== 'undefined')
          if (response.data.success === true && !decoded.mfa_enabled) {
            // localStorage.setItem('oit_jwt', response.data.token);

            // var minutesTest = new Date(new Date().getTime() + 1 * 60 * 1000);

            StorageUtils.storeStorageItem('oit_jwt',response.data.token );
            StorageUtils.storeStorageItem('verified', response.data.success);

            // Cookies.set('oit_jwt', response.data.token, { expires: 1 });
            // Cookies.set('verified', response.data.success, { expires: 1 });

            // let avatarUrl = decoded.avatar
            // axios({
            //   url: 'https://ucdata1.oitdev.io:3000/v1/users/avatar',
            //   method: 'GET',
            //   headers: {
            //     'Authorization': "Bearer " + token,
            //   },
            //   data: {avatar: avatarUrl},
            //   responseType: 'blob',
            // }).then((response) => {
            //   let blob = URL.createObjectURL(response.data)
            //  localStorage.setItem('image', blob);
            // }).catch((error) => {
            //   callback(error)
            // })
            callback(response);
          } else if (
            response.data.success === true &&
            decoded.mfa_enabled === 1
          ) {
            StorageUtils.storeStorageItem('oit_jwt', response.data.token);
            // localStorage.setItem('oit_jwt', response.data.token);
            callback(response);
          } else {
            callback(response);
          }
      })
      .catch(error => {
        console.log('Response', error);
        if (error.response) {
          if (
            error.response.status !== 200 &&
            error.response.status !== 401 &&
            retries < 4
          ) {
            return setTimeout(
              this.login(email, password, retries + 1, callback, failCallback),
              50
            );
          } else if (error.response.status === 401 || retries >= 3) {
            failCallback(error);
          }
        } else {
          failCallback(error);
        }
      });
  };

  /**
   * Logout function, takes a callback, clears localStorage and removes from cookies the jwt token, callback is used to
   * redirect user to login page
   * @param cb
   */
  logout = callback => {
    callback();

    localStorage.clear();
    sessionStorage.clear();
    // Cookies.remove('oit_jwt');
    // Cookies.remove('verified');
    // Cookies.remove('links');
  };

  /**
   * Stores token in the local storage
   * @param token :: JWT token
   */
  storeToken = token => {
    try {
      StorageUtils.storeStorageItem('oit_jwt', token);
      // localStorage.setItem('oit_jwt', token);
      let newtoken = jwt_decode(localStorage.getItem('oit_jwt'));
      console.log("Token successfully stored", newtoken);
    } catch(e) {
      console.log("There was an error", e);
    }
  }

  /**
   * Api call to retrieve sidebar nav elements for the user
   * @param callBack
   * @param failCallback
   */
  retrieveSideNavElements = (callBack, failCallback, token)=>{

    if(!token) {
      token = StorageUtils.retrieveStorageItem('oit_jwt');
    }

    let decodedToken = this.decodeToken(token);

    console.log("DECODED TOKEN", decodedToken);

    if(token) {
      axios({
        url:
          process.env.REACT_APP_HOST +
          process.env.REACT_APP_PORT +
          '/users/navigation',
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token
        },
        data: {}
      }).then(response => {
        let navItems = response.data.items;
        console.log("Response", response);
        callBack({items: navItems.filter((item,index)=>{
            if(item.name === 'PBX') {
              //Checks if there is any pbx role registered
              console.log("Decoded netsapiens", decodedToken.netsapiens && decodedToken.netsapiens.pbx_role);
              return decodedToken.netsapiens && decodedToken.netsapiens.pbx_role;
            }
            return true;
          })

        //     .filter((item,index)=>{
        //   //Check if user is a dev, if so then filter out these fields that have condition 6
        //   if(item.condition === 6) {
        //       return decodedToken.role === 6 || decodedToken.role === "Developer";
        //   } else {
        //     return true;
        //   }
        //   // if(decodedToken.username  )
        //   // return item.condition
        // })
        });
      }).catch(error => {
        console.log("NavBar error", error);
        failCallback(JSON.stringify(error));
      })
    } else {
      console.log("Sidebar nav cannot retrieve without token");
    }
  }


  /**
   * Retrieves jwt token from storage
   * @param failCallBack
   * @returns {string}
   */
  getToken = (failCallBack=()=>{console.log(localStorage.getItem('oit_jwt'))}) => {
    // let token = localStorage.getItem('oit_jwt');
    let token = StorageUtils.retrieveStorageItem('oit_jwt');

    if(token) {
      return token;
    } else {
      failCallBack();

    }
  }

  /**
   * Decodes token
   * @param token
   * @returns {*}
   */
  decodeToken = (token) => {
    let decoded;
    try {
      if(token)
        decoded = jwt_decode(token);
      else
        console.log("Token not available");
    } catch (e) {
      console.log(e, token);
    }

    return decoded;
  }

  /**
   * Interprets error message and checks if invalid token specified
   * @param errorMessage
   */
  checksValidToken = errorMessage => {
    if (errorMessage === 'Invalid token specified') {
      // localStorage.removeItem('oit_jwt');
      StorageUtils.deleteKey('oit_jwt');
      console.log('ChecksValidToken was called');
    }
  };

  /**
   * Validates password, checks if the two password matches and meets the criteria that a password must contain 1 lowercase
   * letter, 1 uppercase letter, and 1 numeric character
   * @param password
   * @param password2
   * @returns {*[]}
   */
  validatePasswords = (password, password2) => {
    let isValid = true;
    let error;

    if(password !== password2) {
      isValid = false;
      error = "Passwords do not match.";
    }

    if(password === password2 && !PASSWORD_REGEX.test(password2)) {
      isValid = false;
      error = "Password must contain: 1 lowercase letter, 1 uppercase letter, 1 numeric character, " +
        "1 special character, and be at least 6 characters long."
    }
    return [isValid,error];

  }

  /**
   * Checks if token is expired
   * @param callback
   * @param redirectCallback
   */
  checkTokenExpiration = (callback, redirectCallback, newToken=this.getToken()) => {
    let token = newToken ;
    let decoded;
    try {
      if (token) {
        // console.log("Token exists");
        decoded = this.decodeToken(token);
        // console.log("Decoded exp", typeof decoded.exp, decoded.exp, moment(decoded.exp).format("MM/DD/YYYY HH:mm:ss"));
        // console.log("Now", typeof moment(new Date()).format('X'), moment(new Date()).format('X'));

        if (decoded.exp > parseInt(moment(new Date()).format('X'))) {
          // console.log("Token is not expired");
          callback();
        } else {
          console.log("Token is expired");
          redirectCallback();
        }
      } else {
        console.log("Token is missing");
        redirectCallback();
      }
    } catch (e) {
      console.log('Error from checks valid token', e);
      redirectCallback();
      // this.checksValidToken(e.message);
    }
  };

  /**
   * This is used in the ProtectedRoute.js and uses the home/test endpoint to check whether or not token is valid, if not
   * returns boolean, which is stored in cookies, if false redirects user to login page.
   * @returns {*}
   */

  isAuthenticated = () => {
    //Verifying that the user is still signed in
    let token = this.getToken();

    let decoded = this.decodeToken(token);

    console.log(decoded);

    return new Promise((resolve, reject) => {

      if (typeof token !== 'undefined') {
        let config = {
          headers: {
            Authorization: 'Bearer ' + token,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': 'true'
          }
        };

        let bodyParameters = {};

        return axios
          .post(
            process.env.REACT_APP_HOST +
              process.env.REACT_APP_PORT +
              '/users/home/test',
            bodyParameters,
            config
          )
          .then(response => {
            // log("success", "Auth", response);
            StorageUtils.storeStorageItem('verified',response.data.success);
            // localStorage.setItem('verified', response.data.success);
            return resolve(response.data.success);
          })
          .catch(error => {
            console.log('Error on home test', error);
            console.log('Error.message', error.message);
            if (error.message) {
              this.checksValidToken(error.message);
            }

            StorageUtils.storeStorageItem('verified', false);
            StorageUtils.deleteKey('oit_jwt');
            // localStorage.setItem('verified', false);
            // localStorage.removeItem('oit_jwt');
            localStorage.clear();

            StorageUtils.storeStorageItem('oit_jwt', '');
            // localStorage.setItem('oit_jwt','');

            window.location.reload(true);
            return reject(false);
            console.log('auth error: ' + error.response.data.description);
          });
        // return localStorage.setItem('verified');
      }
    });
  };
}

export default new Auth();
