
const PALETTE = {
  success: 'background: green; color: white; display: block;',
  info: 'background: blue; color: white; display: block;',
  danger: 'background: red; color: white; display: block;',
  warning: 'background: yellow; color: black; display: block;',
  dark:'background: black; color: white; display: block;',
  light:'background: white; color: black; display: block;'
};

export const log = (color="success", header='Missing', msg='Missing', ...rest) => {
  console.log('%c' + " " + header, PALETTE[color], msg, __dirname, __filename, ...rest )
};
