export const UPDATE_CALL_GRAPH = 'callGraph:updateCalls';

/**
 * Redux action that takes a new array of call data, so the array of counts, and replaces the current state. Reference for the
 * line graph.
 * @param newCallData
 * @returns {{payload: {callGraph: *}, type: string}}
 */
export const updateCallGraph = newCallData => {
  return {
    type: UPDATE_CALL_GRAPH,
    payload: {
      callGraph: newCallData
    }
  };
};
