export const UPDATE_ALERT_MESSAGE = 'alertMessage:updateAlertMessage';
export const ADD_ALERT_MESSAGE = 'alertMessage:addAlertMessage';
export const DELETE_ALERT_MESSAGE = 'alertMessage:deleteAlertMessage';

/**
 * Redux action that takes a new array of call data, so the array of counts, and replaces the current state. Reference for the
 * line graph.
 * @param newCallData
 * @returns {{payload: {callGraph: *}, type: string}}
 */
export const updateAlertMessage = newAlertData => {
  return {
    type: UPDATE_ALERT_MESSAGE,
    payload: {
      alertMessage: newAlertData
    }
  };
};

export const addAlertMessage = newAlert => {
  return {
    type: ADD_ALERT_MESSAGE,
    payload: {
      alertMessage: newAlert
    }
  };
};

export const deleteAlertMessage = message => {
  return {
    type: DELETE_ALERT_MESSAGE,
    payload: {
      alertMessage: message
    }
  };
};
