import { UPDATE_SEARCH_RESULTS } from '../actions/updateSearchResults';

/**
 * Reducer that defines initial state and payload for each scope.
 * @param state
 * @param type
 * @param payload
 * @returns {Array|*}
 */
export default function searchResultsReducer(state = [], { type, payload }) {
  switch (type) {
    case UPDATE_SEARCH_RESULTS:
      return payload.search;
    default:
      return state;
  }
  return state;
}
