import { UPDATE_SEARCH_VALUE } from '../actions/updateSearchValue';

/**
 * Reducer that defines initial state and payload for each scope.
 * @param state
 * @param type
 * @param payload
 * @returns {searchValueReducer|string|Array}
 */

export default function searchValueReducer(state = [], { type, payload }) {
  switch (type) {
    case UPDATE_SEARCH_VALUE:
      return payload.searchValue;
    default:
      return state;
  }
  return state;
}
