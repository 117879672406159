import { UPDATE_COMPANY } from '../actions/updateCompany.js';

/**
 * Reducer that defines initial state and payload for each scope.
 * @param state
 * @param type
 * @param payload
 * @returns {*}
 */

export default function companyReducer(state = null, { type, payload }) {
  switch (type) {
    case UPDATE_COMPANY:
      return payload.company;
    default:
      return state;
  }
  return state;
}
