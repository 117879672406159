import { combineReducers } from 'redux';
import sideBarNavReduce from './sideBarNavReducer';
import companyReducer from './companyReducer';
import userReducer from './userReducer';
import callGraphReducer from './callGraphReducer';
import callReducer from './callReducer';
import timePeriodReducer from './timePeriodReducer';
import searchResultsReducer from './searchResultsReducer';
import searchValueReducer from './searchValueReducer';
import domainReducer from './domainsReducer';
import alertMessageReducer from './alertMessageReducer';
import profilePicReducer from './profilePicReducer';

import { USER_LOGOUT } from '../actions/logoutUser';

const appReducer = combineReducers({
  /* your app’s top-level reducers */

  // state:(state = {})=> state
  company: companyReducer,
  user: userReducer,
  calls: callReducer,
  profilePic: profilePicReducer,
  callGraph: callGraphReducer,
  timePeriod: timePeriodReducer,
  searchResults: searchResultsReducer,
  searchValue: searchValueReducer,
  alertMessage: alertMessageReducer,
  domains: domainReducer,
  sidebarNav: sideBarNavReduce
});

export const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};
