export const UPDATE_SEARCH_RESULTS = 'search:updateSearchResults';

/**
 * For the search bar, onChange will take value and store it in the redux state. Lets the search result page display
 * current search
 * @param searchValue
 * @returns {{payload: {search: *}, type: string}}
 */
export const updateSearchResults = searchValue => {
  // TODO Update this to be named updateSearchResults
  return {
    type: UPDATE_SEARCH_RESULTS,
    payload: {
      search: searchValue
    }
  };
};
