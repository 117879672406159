import { UPDATE_CALL_GRAPH } from '../actions/updateCallGraph';

/**
 * Reducer that defines initial state and payload for each scope.
 * @param state
 * @param type
 * @param payload
 * @returns {callGraphReducer|null|*}
 */

export default function callGraphReducer(state = null, { type, payload }) {
  switch (type) {
    case UPDATE_CALL_GRAPH:
      return payload.callGraph;
    default:
      return state;
  }
  return state;
}
