export const UPDATE_PIC = 'profilePic:updateProfilePic';


export const updateProfilePic = profilePicUrl => {
  return {
    type: UPDATE_PIC,
    payload: {
      profilePic: profilePicUrl
    }
  };
};
