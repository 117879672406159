import io from 'socket.io-client';
//

// class Socket {
//   constructor(ip) {
//     this.socket = io(ip);
//   }
//
//   subscribe = (eventName, callback) => {
//     if(this.socket) this.socket.on(eventName, (data)=>{
//       console.log(`Event ${eventName} was triggered`, data);
//       callback(data);
//     });
//   }
//
//   emit = (eventName, data)=>{
//     if(this.socket) this.socket.emit(eventName, data);
//   }
//
//   unsubscribe = (eventName, callback) => {
//     if(this.socket) this.socket.removeAllEventListeners(eventName);
//     callback();
//   }
//
// }
//
// export default new Socket(process.env.REACT_APP_SOCKET_PORT);

let pingInterval;
let startTime;

export default {
  socket: null,
  connected:false,
  connectionEmitted: false,
  debug: true,
  possibleEvents: ['timer', 'test', 'connect', 'disconnect', "connect_error", "error", "connection_timeout" ,
    'subscribe_to_domain', 'unsubscribe_to_domain', 'alert_new_registered_user', 'unsubscribe_to_all_domains',
    'presence_status_update', 'info'
  ],
  subscribers: {

  },
  log: function(msg, data, ...rest) {
    if (this.debug) console.log('SOCKET LOG: ' + msg, data || "", rest);
  },
  init: function() {
    if (this.socket) return;

    this.socket = io(process.env.REACT_APP_SOCKET_PORT,

      {
        transports: ['websocket'],
         reconnectionDelay: 1000,
         reconnection:true,
         // reconnectionAttempts: 10,
         agent: false, // [2] Please don't set this to true
         upgrade: false,
         rejectUnauthorized: false
      });

    pingInterval = setInterval(()=>{
      startTime = Date.now();
      this.socket.emit('ping');
    }, 2000);


    this.log(`Initializing the socket `);


    // this.socket = io("http://localhost:3001");

    this.possibleEvents.forEach(eventName => {
      this.subscribers[eventName] = [];
      this.socket.on(eventName, data => {
        this.log(`The event ${eventName} was triggered`, data, this.socket.id);
        if(eventName === 'connect') { this.connected = true }
        else if (eventName === 'disconnect') { this.connected = false; clearInterval(pingInterval) }
        this.subscribers[eventName].forEach(callback => {
          this.log(`Calling the callback for ${eventName}`, callback);

          console.log("Socket is connected?", this.socket.connected, `Socket ID ${this.socket.id}`);

          // console.log("Subscribers", this.subscribers);
          //
          // console.log(this.subscribers);
          callback(data);
        });
      });
    });

  },
  subscribe: function(eventName, callback) {
    if (!this.socket)
      throw new Error('You cannot subscribe before inititializing socket');
    if(eventName === "connect") {
      if(this.connected && !this.connectionEmitted) {
        callback();
        this.connectionEmitted = false;
      }
    }

    // this.log('Subscribing for ' + eventName, callback);

      this.subscribers[eventName] = [callback];

      // (this.subscribers[eventName].length < 1) ? this.subscribers[eventName].concat(callback) : this.subscribers[eventName] = [callback];
    // this.socket.on(eventName, callback);
  },
  unsubscribe: function(eventName, callback) {
    if (!this.socket)
      throw new Error('You cannot subscribe before inititializing socket');

    this.subscribers[eventName] = [];
    //   this.subscribers[eventName].filter(
    //   c => c !== callback
    // );

    this.socket.removeAllListeners(eventName);
    if(callback) callback();
  },

  unsubscribeToAll : function() {
    for(let event in this.subscribers) {
      this.subscribers[event] = [];
    }

    for(let event in this.possibleEvents) {
      this.socket.removeAllListeners(event);
    }


  },
  emit: function(eventName, data) {
    if (!this.socket)
      throw new Error('You cannot emit before initializing socket');
    console.log("Emitting ", eventName, new Date());
    this.socket.emit(eventName, data);
  }
};
