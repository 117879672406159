import { UPDATE_USER } from '../actions/updateUser.js';

/**
 * Reducer that defines initial state and payload for each scope.
 * @param state
 * @param type
 * @param payload
 * @returns {*}
 */

export default function userReducer(state = {}, { type, payload }) {
  switch (type) {
    case UPDATE_USER:
      return payload.user;
    default:
      return state;
  }
  return state;
}
