import axios from 'axios';
export const UPDATE_CALLS = 'calls:updateCalls';

/**
 * Redux action that takes array of new call objects and updates the state.
 * @param newCalls
 * @returns {{payload: {calls: *}, type: string}}
 */
export const updateCalls = newCalls => {
  return {
    type: UPDATE_CALLS,
    payload: {
      calls: newCalls
    }
  };
};

// export const fetchCallLogs = () => {
//   return (dispatch) => {
//     return axios.get(https://ucdata1.oitdev.io:3000/v1/pbx/call/read/volume)
//   }
// }
