import { UPDATE_PIC } from '../actions/updateProfilePic';
import DefaultPic from '../../assets/avatars/default.png'

/**
 * Reducer that defines initial state and payload for each scope.
 * @param state
 * @param type
 * @param payload
 * @returns {*}
 */

export default function profilePicReducer(state = DefaultPic, { type, payload }) {
  switch (type) {
    case UPDATE_PIC:
      return payload.profilePic;
    default:
      return state;
  }
  return state;
}
