import {
  UPDATE_ALERT_MESSAGE,
  ADD_ALERT_MESSAGE,
  DELETE_ALERT_MESSAGE
} from '../actions/updateAlertMessage';

/**
 * Reducer that defines initial state and payload for each scope.
 * @param state
 * @param type
 * @param payload
 * @returns {searchValueReducer|string|Array}
 */

// let defaultState = {
//   active: false,
//   message: 'Testing',
//   color:'info',
//   button: null
// }

export default function alertMessageReducer(state = [], { type, payload }) {
  switch (type) {

    case UPDATE_ALERT_MESSAGE:
      return payload.alertMessage;

    case ADD_ALERT_MESSAGE:
      for (let i = 0; i < state.length; i++) {
        if (payload.alertMessage.alertType === state[i].alertType) {
          const alertMessages = [...state].filter(item => {
            return item.alertType !== payload.alertMessage.alertType;
          });
          return [...alertMessages, payload.alertMessage];
        }
      }
      return [...state, payload.alertMessage];

    case DELETE_ALERT_MESSAGE:
      let newState = [...state];

      let index = newState.findIndex(alert => alert.message === payload.alertMessage)
      if(index > -1) {
        newState.splice(index, 1);
      }

      return newState;
      // return newState.filter(item => {
      //   return item.message !== payload.alertMessage;
      // });

    default:
      return state;
  }
  return state;
}
