export const UPDATE_SEARCH_VALUE = 'searchValue:updateSearchValue';

/**
 * Takes a value and updates the search attribute in the redux store.
 * @param searchValue
 * @returns {{payload: {searchValue: *}, type: string}}
 */
export const updateSearchValue = searchValue => {
  return {
    type: UPDATE_SEARCH_VALUE,
    payload: {
      searchValue: searchValue
    }
  };
};
