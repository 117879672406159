export const UPDATE_SIDEBAR_NAV = 'sidebarNav:updateSideNav';

/**
 * Populate side nav bar
 * @param navData
 * @returns {{payload: {sidebarNav: *}, type: string}}
 */
export const updateSideNav = navData => {
  return {
    type: UPDATE_SIDEBAR_NAV,
    payload: {
      sidebarNav: navData
    }
  };
};
