import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import Socket from './config/socket.js';
import { ProtectedRoute } from './auth/ProtectedRoute';
import 'flag-icon-css/css/flag-icon.css';
import { Spinner } from 'reactstrap';


// const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

const loading = color => (
  <div className="col-2 text-center pt-3 mx-auto">
    <Spinner color={color} />
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login/LoginContainer'));
const Register = React.lazy(() => import('./views/Pages/Register/RegisterContainer'));
const Page403 = React.lazy(() => import('./views/Pages/Page403'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const CompanyRegister = React.lazy(() =>
  import('./views/Pages/CompanyRegister')
);
const Loading = React.lazy(() => import('./views/Pages/Loading'));
const MultiFactorAuth = React.lazy(() =>
  import('./views/Pages/MultiFactorAuth')
);
const MFASetting = React.lazy(() => import('./views/Pages/MFASettings'));
const CompanyEdit = React.lazy(() => import('./views/Pages/CompanyEdit'));
const ForgotPassword = React.lazy(() => import('./views/Pages/ForgotPassword'));
const Confirmation = React.lazy(() => import('./views/Pages/ConfirmationPage'));

export class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: ''
    };
    console.log("Subscribers before initialization", Socket.subscribers);
    Socket.init();
    console.log("Subscribers before initialization", Socket.subscribers);

  }

  componentDidMount() {
    // Socket.init();


    // Socket.subscribe('connect', ()=>{
    //   console.log("Successfully connected");
    //   Socket.emit( 'subscribe_to_domain', {domain: 'oit.20463.service'} );
    // });
    // Socket.subscribe('info', (data)=>{console.log("Info from app", data)});
  }

  render() {

    return (
      <BrowserRouter basename="/">
        <React.Suspense fallback={loading('secondary')}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              component={Login}
              // render={props => }
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              component={Register}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              component={Page404}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              component={Page500}
            />
            <Route
              exact
              path="/403"
              name="Page 403"
              component={Page403}
            />
            <Route
              exact
              path="/company-registration"
              name="Company Registration"
              component={CompanyRegister}
            />
            <Route
              exact
              path="/company-edit"
              name="Company Edit"
              component={CompanyEdit}
            />
            <Route
              exact
              path="/loading"
              name="Loading"
              component={Loading}
            />
            <Route
              exact
              path="/verification"
              name="MultiFactorAuth"
              component={MultiFactorAuth}
            />
            <Route
              exact
              path="/password-reset/:token"
              name="Password Reset"
              component={ForgotPassword}
            />
            <Route
              exact
              path="/confirmation/:token"
              name="ConfirmationPage"
              component={Confirmation}
            />

            {/*<Route exact path="/mfa-setting" name="MFASetting" render={props=> <MFASetting {...props}/> }/>>*/}
            <ProtectedRoute
              path="/mfa-setting"
              name={'MFASetting'}
              component={MFASetting}
            />

            {/*<ProtectedRoute path='/password-reset' name="Password Reset" component={ForgotPassword} />*/}
            <ProtectedRoute path="/" name="Home" component={DefaultLayout} />
            {/*<ProtectedRoute path="/dashboard" name="Home" component={DefaultLayout} />*/}
            <ProtectedRoute
              path="/dashboard"
              name="Home"
              component={DefaultLayout}
              // render={props => <DefaultLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}
