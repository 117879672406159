import { UPDATE_TIME_PERIOD } from '../actions/updateTimePeriod.js';

/**
 * Reducer that defines initial state and payload for each scope.
 * @param state
 * @param type
 * @param payload
 * @returns {string|timePeriodReducer|string}
 */

export default function timePeriodReducer(state = 'week', { type, payload }) {
  switch (type) {
    case UPDATE_TIME_PERIOD:
      return payload.timePeriod;
    default:
      return state;
  }
  return state;
}
