import { UPDATE_DOMAINS } from '../actions/updateDomains.js';

/**
 * Reducer that defines initial state and payload for each scope.
 * @param state
 * @param type
 * @param payload
 * @returns {*}
 */

export default function domainsReducer(state = null, { type, payload }) {
  switch (type) {
    case UPDATE_DOMAINS:
      return payload.domains;
    default:
      return state;
  }
  return state;
}
