import { UPDATE_SIDEBAR_NAV } from '../actions/updateSidebarNav';

/**
 * Reducer that defines initial state and payload for each scope.
 * @param state
 * @param type
 * @param payload
 * @returns {sidebarNavReducer|string|Array}
 */

export default function sidebarNavReducer(
  state = { items: [] },
  { type, payload }
) {
  switch (type) {
    case UPDATE_SIDEBAR_NAV:
      return payload.sidebarNav;
    default:
      return state;
  }
  return state;
}
