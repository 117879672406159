

export default {

  /**
   * Fetches value from the storage
   * @param key
   * @returns {string}
   */
  retrieveStorageItem: (key) => {

    return localStorage.getItem(key);
  },

  /**
   * Stores item in storage in the respective key
   * @param key
   * @param value
   */
  storeStorageItem: (key, value) => {
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      console.log(e);
    }
  },

  /**
   * Delete key
   * @param key
   */
  deleteKey: (key)=>{
    localStorage.removeItem(key);
  }
};
