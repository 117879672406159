export const UPDATE_USER = 'user:updateUser';

/**
 * Upon logging in decodes the jwt and stores the users basic information.
 * @param userInfo
 * @returns {{payload: {user: *}, type: string}}
 */

export const updateUser = userInfo => {
  return {
    type: UPDATE_USER,
    payload: {
      user: userInfo
    }
  };
};
