import axios from 'axios';
import Cookies from 'js-cookie';
import AuthUtils from '../../auth/AuthUtils';
export const UPDATE_DOMAINS = 'domains:updateDomains';

export const loadDomains = () => {
  let token = AuthUtils.getToken();

  let config = {
    headers: {
      Authorization: 'Bearer ' + token
    }
  };

  return dispatch => {
    axios
      .get(
        process.env.REACT_APP_HOST +
          process.env.REACT_APP_PORT +
          '/pbx/domains?action=read',
        config
      )
      .then(response => {
        dispatch(updateDomains(response.data.data));
      });
  };
};

/**
 * Redux action that takes a new array of call data, so the array of counts, and replaces the current state. Reference for the
 * line graph.
 * @param newCallData
 * @returns {{payload: {callGraph: *}, type: string}}
 */
export const updateDomains = domains => {
  return {
    type: UPDATE_DOMAINS,
    payload: {
      domains: domains
    }
  };
};
