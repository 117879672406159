import axios from 'axios';

export const UPDATE_COMPANY = 'company:updateCompany';

/**
 * Takes the company object selected it and stores it in the company state.
 * @param newCompany
 * @returns {{payload: {company: *}, type: string}}
 */
export const updateCompany = newCompany => {
  return {
    type: UPDATE_COMPANY,
    payload: {
      company: newCompany
    }
  };
};
