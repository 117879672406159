import { applyMiddleware, compose, createStore } from 'redux';

import { rootReducer } from '../reducers/appReducer';
import thunk from 'redux-thunk';

function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (e) {
    console.log(e);
  }
}

function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    console.log(e);
    return undefined;
  }
}

const persistedState = loadFromLocalStorage();

const allStoreEnhancers = compose(
  applyMiddleware(thunk),
  window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : f => f
);

// const store = createStore(rootReducer);

/**
 * Takes all reducers and definies inital state. Store enhancers include middleware and devtool extensions.
 * @type {Store<any, AnyAction> & Store<S & {}, A> & {dispatch: any}}
 */
const store = createStore(rootReducer, persistedState, allStoreEnhancers);

store.subscribe(() => {
  saveToLocalStorage(store.getState());
});

export default store;
